.chart {
    height: 300px;
    margin-top: 40px;
}

.box {
    text-align: center;
    padding: 12px;
    cursor: pointer;
}
