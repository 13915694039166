@import "../DesignSystem/DesignSystem.style.less";

:global {
    .contentLayout--fullWidthTable {
        padding: 0;
    }

    .contentLayout--fullWidth {
        padding: @pfx-spacing-lg;
    }

    .contentLayout--leftAligned {
        width: @pfx-content-width;
        padding: @pfx-spacing-lg;
    }

    .contentLayout--centered {
        > .contentLayout__inner {
            width: @pfx-content-width;
            padding: @pfx-spacing-lg;
        }

        @media (min-width: 1522px) {
            margin-left: -@pfx-menu-width;
            > .contentLayout__inner {
                margin: 0 auto;
            }
        }
    }
}
