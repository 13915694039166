@import "../DesignSystem/DesignSystem.style.less";

.gridContainer {
    display: grid;
    grid-template-columns: 1fr 45% 1fr 1fr;
}

.gridItem {
    padding: 10px;
}

.columnSpan {
    grid-column: span 4;
}

.greyBackground {
    background-color: @pfx-color-grey-3;
}

.collapseWhite {
    border: "none";
    background: @pfx-color-white;
}

.collapseGrey {
    border: "none";
    background: @pfx-color-grey-3;
}
