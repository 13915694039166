:global {
    .pmUpgradeForm {
        .ucCheckbox--defaultLabel.ant-checkbox-wrapper > span + span {
            white-space: normal;
        }
    }
}

.clusterVersion {
    display: grid;
    grid-template-columns: 448px 1fr;
}

.timePlanner {
    display: grid;
    grid-template-columns: 313px 367px;
    gap: 15px;
}

.timeIntervals {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 9px;
    & > * {
        margin-left: 0 !important;
    }
}

.errorWrapper {
    position: relative;
    left: 50%;
    width: 400px;
    margin-left: -200px;
    height: 0;
    overflow: visible;
}

.flashError {
    position: relative;
    top: 32px;
}
