:global {
    .pmPartitionsWithFlag-loading {
        margin-left: auto;
        margin-right: auto;
        max-width: 300px;
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 170px);
        justify-content: center;
        align-items: center;
        text-align: center;
        img {
            display: inline-flex;
            width: 200px;
            height: 200px;
        }
    }
}
