@import "../../../DesignSystem/DesignSystem.style.less";

:global {
    .pmConverter {
    }

    .pmConverter--active {
        svg {
            color: @pfx-color-blue-4;
        }
    }
}

.valueType {
    padding-right: 8px;
}
