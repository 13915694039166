@import "../../../DesignSystem.style.less";

&:global {
    .CodeMirror-password-encrypt {
        width: 16px;
    }

    .CodeMirror-password-encrypt-marker-fixable {
        position: relative;
        height: 16px;
        &::before {
            content: "";
            width: 16px;
            height: 16px;
            position: absolute;
            inset: 0;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ib3JhbmdlIj48cGF0aCBkPSJNMTIsMTZhMSwxLDAsMSwwLDEsMUExLDEsMCwwLDAsMTIsMTZabTEwLjY3LDEuNDctOC4wNS0xNGEzLDMsMCwwLDAtNS4yNCwwbC04LDE0QTMsMywwLDAsMCwzLjk0LDIySDIwLjA2YTMsMywwLDAsMCwyLjYxLTQuNTNabS0xLjczLDJhMSwxLDAsMCwxLS44OC41MUgzLjk0YTEsMSwwLDAsMS0uODgtLjUxLDEsMSwwLDAsMSwwLTFsOC0xNGExLDEsMCwwLDEsMS43OCwwbDguMDUsMTRBMSwxLDAsMCwxLDIwLjk0LDE5LjQ5Wk0xMiw4YTEsMSwwLDAsMC0xLDF2NGExLDEsMCwwLDAsMiwwVjlBMSwxLDAsMCwwLDEyLDhaIi8+PC9zdmc+");
            cursor: pointer;
        }
    }

    .CodeMirror-password-encrypt-popup,
    .CodeMirror-password-encrypt-popup-marker {
        margin-left: -1px;
        border: 1px solid silver;
        border-radius: 3px;
    }

    .CodeMirror-password-encrypt-popup {
        background: transparent;
        position: absolute;
        z-index: 99999;
        max-height: 20em;
        overflow-y: auto;
        box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
        // border-top-left-radius: 0;
    }

    /* slight priority boost over custom marker styles which normally include relative position */
    .CodeMirror-password-encrypt-popup-marker.CodeMirror-password-encrypt-popup-marker {
        display: block;
        padding: 0;
        z-index: 11;
        position: absolute;
        margin-top: -1px;
        background-color: white;
        border-bottom: 1px solid white;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .CodeMirror-password-encrypt-list {
        background-color: white;
        list-style: none;
        margin: 0;
        padding: 2px;
    }

    .CodeMirror-password-encrypt-fix {
        margin: 0;
        padding: 2px 4px;
        border-radius: 2px;
        white-space: pre;
        font-size: @text-12;
        color: @text-color;
        cursor: pointer;
    }

    .CodeMirror-password-encrypt-fix-selected {
        background-color: #eee;
    }
}
