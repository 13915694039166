@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

:global {
    .pmUpdateChecker {
        position: fixed;
        top: 8px;
        z-index: 999999;
        left: 50%;
        transform: translateX(-50%);
        min-width: 605px;
        animation: handleFadeIn 200ms ease-in-out;

        .ucAlert {
            margin-bottom: 0;
        }
    }
}
