@import "../../components/DesignSystem/DesignSystem.style.less";
:global {
    .Widget01 {
        display: flex;
        margin-top: 12px;
        margin: 12px;
        min-height: 88px;
        column-gap: 12px;

        &_icon {
            display: grid;
            width: 100%;
            height: 100%;
            font-size: 26px;
            color: #fff;
            place-items: center;
        }

        &_textWrapper {
            display: flex;
            flex-direction: column;
            align-content: center;
        }

        &_mainText {
            color: #536c79;
            font-weight: bold;
            font-size: 12px;
        }

        &_footer {
            color: #536c79;
            font-weight: 700;
            font-size: 12px;
            text-transform: uppercase;
        }

        &_item {
            display: grid;
            grid-template-columns: 80px 1fr;
            flex-direction: row;
        }

        &_title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &_value {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .Widget01--shorter {
        min-height: 62px;
    }

    .Widget01--primary {
        .Widget01_icon {
            background-color: @pfx-color-primary;
        }

        .Widget01_primaryText {
            color: @pfx-color-primary;
        }
    }

    .Widget01--danger {
        .Widget01_icon {
            background-color: @pfx-color-red-3;
        }

        .Widget01_primaryText {
            color: @pfx-color-red-3;
        }
    }
}
