@import "../../../../DesignSystem/DesignSystem.style.less";

.logFileParent {
    height: 75vh;
}

.logFile {
    white-space: pre-line;
    overflow-x: scroll;
    height: 100%;
    padding: 15px;
    border: 1px solid @border-color;
}
