:global {
    .uploadPreview {
        .ant-upload-list-item-actions a {
            display: none;
        }
    }
    .uploadPreviewFullSize {
        .ant-upload-list-item-actions a {
            display: none;
        }
        .ant-upload-list-picture-card-container {
            width: 100% !important;
        }
        .ant-upload.ant-upload-select-picture-card {
            width: 100% !important;
        }
    }
    .templateEditForm {
        .ucForms .ant-form-item.ucFormsField--spacing-default {
            margin: 0;
        }
    }
}
