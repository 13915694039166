:global {
    .capacityConfiguration {
        .capacityEntry {
            .ant-checkbox-wrapper {
                display: flex;
                margin-top: 10px;
                margin-left: 6px;
            }
        }
        .capacityEntry-separator {
            line-height: 30px;
        }
    }
}

.addRowButton {
    width: 100%;
    button {
        width: 100%;
    }
}

.row {
    max-width: 459px;
}
