@import "../../../../components/DesignSystem/DesignSystem.style.less";

.description {
    padding: @pfx-spacing-8 @pfx-spacing-16 0 @pfx-spacing-24;
}

.permissionTable {
    th {
        border: 0 !important;
        background: transparent !important;
    }

    td {
        border: 0 !important;
        background: transparent !important;
    }
}

.accountTable {
    table thead {
        display: none;
    }

    table table thead {
        display: initial;
    }

    th {
        background: #fbfbfb !important;
    }

    .assetTable {
        table {
            thead {
                display: table-header-group;
            }
        }
    }
}
