@import "../DesignSystem/DesignSystem.style.less";

:global {
    .pmCompositeSelect {
        display: grid;
        grid-template-columns: 112px 1fr;
        & > :first-child {
            place-self: center;
        }
    }
}
