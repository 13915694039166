@import "../../../components/DesignSystem/DesignSystem.style.less";

:global {
    .pmMapperTableWithCustomization {
        .ant-form-item-label {
            padding-bottom: 8px !important;
            padding-left: 8px !important;
        }

        .ucFormsField .ucFormsField .ant-form-item-control:first-child {
            padding-top: 0 !important;
        }

        .ucFormsField--spacing-default {
            margin-bottom: 10px;
        }

        .ucFormsFieldGrid__row {
            border-bottom: 1px solid @pfx-color-grey-5;

            & > .ucFormsField:first-child {
                max-width: 35%;
            }

            .ucFormsField {
                .ant-form-item-label {
                    padding-left: 0 !important;
                }
            }
            .ucFormsField:nth-child(2) {
                .ant-form-item-label {
                    padding-left: 43px !important;
                }
            }
        }
    }
    .pmMapperTableWithCustomization-cell {
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
        margin-top: -6px;
    }
    .pmMapperTableWithCustomization-converter {
        max-width: 100px;
    }

    .pmMapperTableWithCustomization-input {
        .ant-input-group {
            display: table;
            width: 100%;

            .pmCascader {
                display: table-cell;
                width: 100%;
                max-width: 310px !important;

                .ant-select-selector {
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                }
            }
            .ant-input-group-addon {
                display: table-cell;
            }
        }
    }
    .pmMapperTableWithCustomization-freetext-input {
        .ucFormsField--width-default {
            width: 100%;

            &.ucFormsField--inputWidth-default
                > .ant-form-item-row
                > .ant-form-item-control
                > .ant-form-item-control-input
                > .ant-form-item-control-input-content {
                width: 100% !important;
            }
        }
    }
    .pmMapperTableWithCustomization-convert {
        display: flex;
        flex-direction: column;
        width: 165px !important;
        gap: 10px;
        padding-right: 30px;
        padding-left: 30px;
    }

    .pmConverterIcon {
        font-size: 14px;
    }
}
