.wrapper-bak {
    display: grid;
    grid-template-columns: 3fr 1fr;
    // grid-template-columns: 3fr 1fr 2fr; // not looking nice
    grid-auto-flow: column;
    gap: 16px;
    padding: 16px 16px 0;
    margin-bottom: 16px;
}

.wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-end;
    gap: 16px;
    padding: 16px 16px 0;
    margin-bottom: 16px;
}
