@import "../DesignSystem/DesignSystem.style.less";

.activityLog {
    border: 1px solid @border-color;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 20px 20px 0px 20px;
}

.lastItemWithoutPadding {
    padding-bottom: 0px;
}
