@import "../../../DesignSystem/DesignSystem.style.less";

:global {
    .pmMarketplaceItem {
        position: relative;
        width: 100%;
        min-height: 120px;
        background: @pfx-color-grey-2;
        border: 1px solid @pfx-color-grey-2;
        padding: 24px 32px;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .pmFavoriteButton {
            opacity: 0;
        }

        &:hover .pmFavoriteButton,
        .pmFavoriteButton.pmFavoriteButton--active {
            opacity: 1;
        }
        &:hover {
            transition: all 200ms ease;
            background: @pfx-color-grey-4;
            border: 1px solid @pfx-color-grey-4;

            .pmFavorites {
                opacity: 1;
            }
        }

        &-title {
            font-size: 15px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.0002em;
            margin-bottom: 16px;
        }

        &-favorites {
            position: absolute;
            top: 10px;
            right: 10px;
        }

        &-tag {
            margin-right: 4px;
            margin-bottom: 4px;
        }
    }
}
