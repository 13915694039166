@import "../../../../components/DesignSystem/DesignSystem.style.less";

.valueType {
    display: inline-block;
    text-transform: lowercase;
    &:first-letter {
        text-transform: uppercase;
    }
}

.error {
    color: @pfx-color-red-4;
}
