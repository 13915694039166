@import "../../../DesignSystem/DesignSystem.style.less";

:global {
    .pmTemplateDetail-description {
        h2,
        h1,
        h3,
        h4,
        h5,
        h6 {
            font-size: 12px;
            line-height: 16px;
        }
    }
}
