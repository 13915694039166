:global {
    .cascaderWithAddonFix {
        // line-height: 32px;

        .ant-cascader-picker-label {
            z-index: 10;
            pointer-events: none;
            padding-right: 100px;
        }
        .ant-cascader-picker-arrow {
            display: none;
        }
    }
}
