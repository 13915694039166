@import "../../../DesignSystem/DesignSystem.style.less";

.input {
    margin-top: 2px;
}

.icon {
    width: 16px;
    height: 16px;
    fill: @pfx-color-grey-6;
}
