@import "../../../DesignSystem/DesignSystem.style.less";

:global {
    .pmHeaderNotificationsItem {
        position: relative;
        margin: 0 0.25rem;
        border-radius: 4px;
        padding: 0.5rem 2rem 0.5rem 2.5rem;
        transition: 0.1s background-color ease-in-out;
        cursor: pointer;

        &:hover {
            background: @pfx-color-grey-3;

            a {
                text-decoration: underline;
            }
        }

        &:hover {
            background: #f5f5f5;
            transition: background 0.3s;
        }

        &:first-child {
            border-top-color: transparent;
        }

        &__status {
            position: absolute;
            top: 0.5rem;
            left: 0.75rem;

            &--type {
                &-error {
                    color: @pfx-color-red-4;
                }
                &-failed {
                    color: @pfx-color-red-4;
                }

                &-success {
                    color: @pfx-color-green-4;
                }

                &-warning {
                    color: @pfx-color-yellow-4;
                }

                &-info {
                    color: @pfx-color-blue-4;
                }
            }
        }

        &-date {
            color: @pfx-color-grey-7;
            font-weight: 400;
        }

        &__title {
            font-size: 13px;
            color: @pfx-color-grey-9;
            letter-spacing: -0.006em;
            font-weight: 500;
        }

        &__time {
            font-size: 11px;
            color: @pfx-color-grey-7;
            margin: 0.5rem 0 0;
        }

        &__unreadMark {
            position: absolute;
            top: 0.5rem;
            right: 0.75rem;
            width: 0.5rem;
            height: 0.5rem;
            background: @pfx-color-blue-4;
            border-radius: 50%;
        }

        &__message {
            font-size: 13px;
            color: @pfx-color-grey-9;

            &--outdated {
                color: @pfx-color-grey-7;
            }

            p {
                margin-bottom: 0.25rem;
            }
        }
    }
}
