@import "../../../../DesignSystem/DesignSystem.style.less";

.inlineContainer {
    display: grid;
    grid-template-columns: 1fr 38px;
    justify-content: flex-start;
    align-items: center;
}

.error {
    width: 100%;
    border-color: @error !important;
}
