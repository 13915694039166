@import "../DesignSystem/DesignSystem.style.less";

.alertIconError {
    color: @pfx-color-red-4 !important;
    height: 15px;
}

.alertIconWarning {
    color: @pfx-color-yellow-4 !important;
    fill: @pfx-color-yellow-4 !important;
    height: 15px;
}

.alertCellContainer {
    display: flex;
    align-items: center;
    gap: 4px;
}
