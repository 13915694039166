.progress {
    margin-top: 24px;
}

.notificationRow {
    display: flex;
    place-items: center;
    flex-direction: row;

    & > :first-child {
        margin-right: 12px;
    }
}
