@import "../../../../../components/DesignSystem/DesignSystem.style.less";

.groupTable {
    overflow: auto;
    margin: 0 -@pfx-spacing-md;
}

.negativeMarginSm {
    margin: 0 -@pfx-spacing-sm;
}
