@import "../../../DesignSystem/DesignSystem.style.less";

.wrapper {
    display: flex;
    margin-bottom: 12px;

    & > div {
        display: flex;
        flex: 1;
        align-items: center;
    }
}

.counter {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.006em;
    margin-left: 20px;
}
