@import "../../../DesignSystem/DesignSystem.style.less";

:global {
    .pmFavoriteButton {
        width: 26px;
        height: 26px;
        right: 10px;
        padding: 6px;
        cursor: pointer;
        fill: @pfx-color-yellow-4;
        transition: 0.2s ease all;
        &:hover {
            transform: scale(1.25);
        }
        &:active {
            transform: scale(1.1);
        }
    }
}
