:global {
    .pmSlotsCalendar {
        .ant-picker-cell-inner {
            position: relative;
        }
        .ant-badge {
            position: absolute;
            top: -8px;
            right: -8px;
        }
    }
}
