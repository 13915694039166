:global {
    .pm-collapse-group {
        &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
            padding-left: 0;
        }

        .ant-collapse-content-box {
            padding: 0 0 12px !important;
            border-bottom: 1px solid #ebecf0;
        }
    }
}
