@import "../../../DesignSystem/DesignSystem.style.less";

.tableBackground {
    background: @table-bg;
}

.validRow {
    background: #f6fff7;
}

.headingColor {
    color: @heading-color;
}

.descriptionColor {
    color: @text-color-secondary;
}

.textDisabled {
    color: @disabled-color;
}

.itemSelect {
    width: 100%;
}

.settingButton {
    margin-left: 8px;
    padding: 0 10px;
}

.infoModalButtonLayout {
    display: flex;
    flex-direction: column;
}

.infoModalButtonLayout * {
    margin-top: 8px;
    margin-left: 0 !important;
}

.infoModalButtonLayout :first-child {
    margin-top: 0;
}

.businessKey {
    color: #2955a3;
    & * {
        margin-left: -2px !important;
    }
}

.ppField {
    color: #2955a3;
}

.skipped {
    color: @disabled-color;
    & * {
        color: @disabled-color;
    }
}

.type {
    color: @text-color-secondary;
}

.extensionInputs :first-child {
    margin-right: @pfx-spacing-24;
}

.previewTable tbody {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 100%
    ) !important;
}

.matrixLabel {
    width: 288px;
    margin-right: @pfx-spacing-24;
}
.matrixKeySelect {
    width: 168px;
}

.skipTitle {
    margin-right: @pfx-spacing-8 !important;
    display: inline;
}

.groupSkipTitle {
    margin-right: 19px !important;
}

.skipAll {
    display: inline-flex;
    font-size: @text-12 !important;
    place-items: center;
}

.switchHeader {
    margin-right: 160px !important;
    min-width: 350px;
}

.valueSelect {
    min-width: 350px;
}

.flexContainer {
    display: flex;
}

.inlineContainer {
    display: inline-flex;
}
