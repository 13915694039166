.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pageContainer {
    min-height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
