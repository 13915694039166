@import "../DesignSystem/DesignSystem.style.less";

.breadcrumb {
    margin-top: -1px;
    padding: 0.4rem 1rem;
    font-size: 12px;
    background: @pfx-color-grey-2;
    border-top: 1px solid @border-color;
    border-bottom: 1px solid @border-color;
    height: 36px;
}

.breadcrumbButton {
    display: flex;
    justify-content: flex-end;
    align-content: center;
}
