.inlineContainer {
    display: grid;
    grid-template-columns: 163px 24px;
    grid-gap: 8px;
    justify-content: center;
    align-items: center;
}

.inlineContainerSubLevel {
    display: grid;
    grid-template-columns: 159px 24px;
    grid-gap: 8px;
    justify-content: center;
    align-items: center;
}

.waterfallInput {
    width: 163px;
}

:global {
    .waterfallTable {
        width: 887px;

        .ucTableCellContent {
            display: flex;
            justify-content: center;
            place-items: center;
        }

        .pmInput {
            max-width: 220px;
        }

        .ucTableExpandIcon {
            position: relative;
            left: 4px;
            top: 10px;
        }

        .ucTableDragHandle {
            height: 50px;
            left: 0;

            .anticon {
                margin: 17px 5px;
            }

            .ucTableRow {
                .ucTableDragHandle {
                    display: none;
                }
            }
        }
    }

    .waterfallTable--subLevel {
        width: 100%;

        .ucTableDragHandle {
            left: 0;
        }
    }
}
