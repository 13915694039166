@import "../DesignSystem/DesignSystem.style.less";

.questionMark {
    display: inline-block;
    line-height: 1rem;
    width: 1rem;
    height: 1rem;
    background: @pfx-color-grey-4;
    border-radius: 2px;
    color: @pfx-color-grey-8;
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    margin-left: 2px;
    user-select: none;
}
