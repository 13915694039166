:global {
    .pmCustomerDashboard {
        display: flex;
        flex-direction: row;

        & > * {
            margin-left: 24px;
            &:first-child {
                margin-left: 0;
            }
        }

        .ant-statistic-content {
            font-size: 20px;
        }
    }
}
