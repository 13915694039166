.wrapper {
    max-width: 275px;
    text-align: center;
    margin-top: 50px;
    margin-left: 50px;
}

.image {
    max-width: 125px;
}

.title {
    margin-bottom: 8px;
}
