:global {
    .pmConditionalErrorAlert {
    }

    .pmConditionalErrorAlert--small {
        margin: 8px;
    }

    .pmConditionalErrorAlert--medium {
        margin: 16px;
    }

    .pmConditionalErrorAlert--large {
        margin: 24px;
    }

    .pmpmConditionalErrorAlert--x-large {
        margin: 30px;
    }

    .pmpmConditionalErrorAlert--huge {
        margin: 34px;
    }
}
