@import "../../../../DesignSystem/DesignSystem.style.less";

.wrapper {
    display: flex;
    gap: 16px;
    flex-direction: row;
    place-items: end;
    flex-grow: 2;
}

.searchInput {
    width: 100%;
}

.queriesButton {
    display: flex;
    flex-direction: row;
    position: relative;
    padding-right: 42px;

    &:after {
        position: relative;
        left: 6px;
        top: 2px;
        content: "";
        display: block;
        background-color: @pfx-color-grey-5;
        width: 1px;
        height: 16px;
    }
}

.queriesIcon {
    position: absolute;
    right: 12px;
    width: 20px;
    fill: @pfx-color-grey-7;
    place-self: center;
}
