:global {
    .pmFeatureFlags-emptyScreen {
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 165px);
        justify-content: center;
        align-items: center;
        text-align: center;
        img {
            display: inline-flex;
            width: 168px;
            height: 138px;
            margin-bottom: 24px;
        }
    }

    // @TODO investigate why it's needed, broken loader
    .pmFeatureFlags-table {
        .ant-spin-nested-loading .ant-spin .anticon svg {
            width: 32px;
            height: 32px;
        }
    }

    .pmFeatureFlags-name {
        font-size: 12px;
        font-weight: 600;
    }

    .pmFeatureFlags-description {
        font-size: 12px;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .pmFeatureFlags-value {
        input {
            font-size: 12px !important;
            padding: 3px 7px !important;
        }
    }

    .pmFeatureFlags-centeredCell {
        display: flex;
        align-items: center;
        height: 100%;

        .ant-form-item {
            margin-bottom: 0 !important;
        }
    }

    .pmFeatureFlags-highlight {
        position: relative;
        &:before {
            position: absolute;
            content: "";
            background-color: #fffbe6;
            inset: -8px;
            bottom: -16 x;
            // margin-top: -6px;
            // margin-left: -8px;
            // margin-right: -8px;
            // min-height: 48px;
        }
    }
}
