:global {
    .pmGreyForm {
        padding: 12px;
        background-color: #f4f5f7;

        .ucFormsFieldGroup {
            margin-top: 0;
        }
    }
}
