@import "../../../DesignSystem/DesignSystem.style.less";

:global {
    .pmNotificationIcon {
        color: @pfx-color-grey-7;
    }

    .pmHeaderNotifications-content {
        width: 360px;
    }
}
