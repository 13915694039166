:global {
    .pmBusinessKeyLengthTable {
        &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
            padding-right: 0;
        }

        .ant-collapse-content-box {
            padding: 0 !important;
        }

        .ucInputNumber {
            max-width: 100px !important;
        }

        .ucFormsField {
            margin-bottom: 0 !important;
        }

        .ant-form-item-extra {
            display: none;
        }
    }

    .pmBusinessKeyLengthTable-error {
        color: #e00131;
    }
}
