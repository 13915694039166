@import "../../../DesignSystem/DesignSystem.style.less";

:global {
    .pmTagsMenu {
        .pmMenu.ucMenu--theme-pm-tags {
            .ucMenu__item {
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: -0.006em;
                color: #595959;
                border: 1px solid transparent;

                &:focus,
                &:hover {
                    background: none;
                    color: @pfx-color-blue-4;
                    border: 1px solid transparent;
                }

                &.ant-menu-item-selected {
                    background: none;
                    color: @pfx-color-blue-4;
                    &:after {
                        right: auto;
                        top: 0;
                        left: 0;
                        bottom: 0;
                    }
                }
            }
        }
    }

    .pmTagsMenu-title {
        display: block;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.006em;
        padding-left: 22px;
        margin-bottom: 10px;
        padding-top: 20px;
        border-top: 1px solid @pfx-color-grey-4;
    }
}
