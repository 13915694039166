:global {
    .pmRowSkeleton {
        .ant-skeleton-avatar {
            width: 100px;
            height: 12px;
            border-radius: 2px !important;
        }

        &-marketplace-item {
            .ant-skeleton-header {
                padding-right: 0;
            }
            .ant-skeleton-avatar {
                width: 100%;
                height: 120px;
            }
        }
    }
}
