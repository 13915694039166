@import "../DesignSystem/DesignSystem.style.less";

.wrapper {
    border: 1px solid @pfx-color-blue-4;
    color: @pfx-color-blue-4;
    background: @pfx-color-white;
    font-size: 14px;
    line-height: 32px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    padding: 0 7px;

    &:hover {
        color: @pfx-color-white;
        background: @pfx-color-blue-4;
    }
}

.isSelected {
    color: @pfx-color-white;
    background: @pfx-color-blue-4;
}
