@import "../../../components/DesignSystem/DesignSystem.style.less";

:global {
    .pmCellWrapper {
        display: flex;
        justify-items: center;
        align-items: center;
        width: 100%;

        .ant-legacy-form-item {
            padding-bottom: 0 !important;
        }

        .ant-row {
            width: 100%;
            margin: 0;
        }

        .ant-legacy-form-item-control,
        .ant-form-item-control {
            line-height: initial;
        }

        .pmIcon {
            margin-left: 10px;
        }
    }

    .pmConverterIconSet {
        svg {
            fill: @pfx-color-blue-4;
        }
    }

    .pmConverterIconWarn {
        svg {
            color: @warning-color;
        }
    }
}

.selectInput {
    width: 100%;
}
